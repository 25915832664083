import {
  Button,
  ButtonGroup,
  Footer,
  H4,
  Navbar,
  Table,
  Text,
} from "@dossier/mithra-ui";
import React, { ReactNode, useState } from "react";
// import { useLocalStorage } from "react-use";
import "./App.scss";

type LoginMode = "helseid" | "direct";

export default function App() {
  const [loginMode, setLoginMode] = useState<LoginMode>(
    // "hso_LastLoginMode",
    "helseid"
  );

  return (
    <div className="App">
      <Navbar />

      <main>
        <H4 as="h1">Dossier Compliance Center</H4>

        <h2>Introduction to sub-processors</h2>
        <Text variant="body-2">
          Dossier uses sub-processors to deliver our services to our customers.
          These sub-processors is also described in our agreement (DPA).
        </Text>
        <Text variant="body-2">
          Dossier evaluates the security and information security practices of our selected sub-processors.
          Providers need to adhere to all our information security requirements before we approve the use of the services provided.
          </Text>
        <h2>Notification Process</h2>
        <Text variant="body-2">
          All of our customers will be notified of any changes to our currently used sub-processors.
          The list will be continiously updated as described in our Data Processing Agreement.
        </Text>
        <h2> SaaS Specific Sub-Processors </h2>
        <Text variant="body-2">
          Current sub-processers in use to deliver our SaaS service to customers.
          All vendors has been thru our own internal due-dilligence / compliance process.
          Services provided by these sub-processors, is described in the purpose tab in a general description, but not limited to the services described.
        </Text>
        <Table>

          <tr>
            <th>Name</th>
            <th>Storage</th>
            <th>Purpose</th>
          </tr>
          <tr>
            <td>Basefarm AS</td>
            <td>EU (Norway)</td>
            <td>Hosting company located in Oslo. Our Norwegian SaaS service is hosted by this sub-processor</td>
          </tr>
          <tr>
            <td>TietoEVRY AS</td>
            <td>EU (Norway)</td>
            <td>Provider is used for our Norwegian SaaS service, as a hosting provider. We consider this a legacy environment, as we are currently migrating customers to Basefarm AS</td>
          </tr>
          <tr>
            <td>Amazon Web Services - Hosting</td>
            <td>EU (Frankfurt / Stockholm) & USA</td>
            <td>Hosting provider for our American / Nordic / EU SaaS environment. (Hosting, SMS, email relay, and other services)</td>
          </tr>
          <tr>
            <td>Matomo</td>
            <td>EU (Norway) - Self Hosted</td>
            <td>Analytics on user patterns for product development purposes. Does not process any user-data that can provide the identity of the user</td>
          </tr>
          <tr>
            <td>Elastic.io</td>
            <td>EU (Norway) - Self Hosted</td>
            <td>Performence data used for our internal development processes. To idenitfy bottlenecks or usage issues within the application. Does not contain any user specific information</td>
          </tr>
        </Table>

        <h2> Business Support / Service Specific Sub-Processors </h2>
        <Text variant="body-2">
          Sub-processors used for managing customer relations within the company.
          Services provided by these sub-processors, is described in the purpose tab in a general description, but not limited to the services described.
        </Text>
        <Table>
          <tr>
            <th>Name</th>
            <th>EU</th>
            <th>Purpose</th>
          </tr>
          <tr>
            <td>Zendesk</td>
            <td>EU</td>
            <td>Used for handling incident management, and change management within Dossier.</td>
          </tr>
          <tr>
            <td>Hubspot</td>
            <td>EU/USA</td>
            <td>CRM system used for customer relations. Data is seperated by region.</td>
          </tr>
        </Table>
      </main>

      <Footer>
        <Text>&copy; Dossier Solutions &ndash; All rights reserved</Text>
      </Footer>
    </div>
  );
}